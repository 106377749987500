import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { asrLogo, asrScreenshot } from 'images/koppelingen'

const dummyKlantVerhaal = {
  content: [{
    title: 'Over a.s.r Inkomensverzekering',
    description: 'Via a.s.r kun je als werkgever onder andere een verzuim- of inkomensverzekering afsluiten voor je werknemers. Voor de juiste bepaling van de premie heeft a.s.r gegevens nodig vanuit je salarisadministratie. Het aanleveren van deze gegevens kan een behoorlijk tijdrovende klus zijn. Om dit te voorkomen is er een (gratis) koppeling tussen Employes en a.s.r.'
    }, {
      title: 'De werking van de koppeling met a.s.r Inkomensverzekering',
      description: 'Om gebruik te maken van de koppeling tussen a.s.r en Employes, dien je de salariskoppeling aan te vragen bij a.s.r. Meer informatie daarover vind je via de link onderaan deze pagina. Nadat je de salariskoppeling hebt aangevraagd, zullen wij op de achtergrond in samenwerking met a.s.r de koppeling voor je activeren. Daarna zullen wij maandelijks alle werknemergegevens op de 26e aanleveren bij a.s.r. Tevens zal er na afloop van het jaar een jaaraanlevering plaatsvinden.',
    },{
      title: 'Functies van de koppeling',
      description: 'De functies van de koppeling zijn:',
      list: ['Versturen van werknemergegevens naar a.s.r.','Jaarlijkse aanlevering SV-lonen naar a.s.r.']
    }, {
      image: asrScreenshot
    }, {
      title: 'Tarieven van de koppeling',
      description: 'De koppeling tussen Employes en a.s.r Inkomensverzekering is geheel gratis!'
  }]
}

class asrInkomen extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

        <Helmet>
          <title>Koppeling a.s.r Inkomensverzekering & Employes | Salarisadministratie</title>
          <meta name="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van a.s.r Inkomensverzekering."/>
          <meta itemprop="name" content="Koppeling a.s.r Inkomensverzekering & Employes | Salarisadministratie" />
          <meta itemprop="description" content="Automatisch gewerkte uren importeren in je Employes salarisadministratie met de koppeling van a.s.r Inkomensverzekering."/>
          <meta itemprop="image" content="/static/meta-img.png" />
        </Helmet>

          <header className="koppeling asr padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel je a.s.r Inkomensverzekering aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                  <span className="hide-mobile">
                    <img src={asrLogo} alt="asr logo"/> 
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const isVideo = (firstObjectKey === 'video')
                  const { title, description, list } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isVideo ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isVideo ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                {description && (<p>{description}</p>)}
                                {list && list.length && (<ul>{list.map(point => (<li>{point}</li>))}</ul>)}
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section" />
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <a href="https://support.employes.nl/nl/articles/8450740-a-s-r-inkomensverzekering-koppeling-instellen" target="_blank" rel="nofollow">Uitleg over het instellen van de koppeling</a>
                </div>
              </div>
            </div>
          </div>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

asrInkomen.propTypes = {
  data: PropTypes.object
}

export default asrInkomen
